import React from 'react'
import { Card, Text, Heading, Box } from 'theme-ui'
import NewsletterForm from '@components/NewsletterForm'
import Section from '@components/Section'
import useSGAutorepondeur from '@helpers/useSGAutorepondeur'

const NewsletterCompact = props => {
  const {
    handleSubmit,
    canSubmit,
    submitting,
    message,
    success,
  } = useSGAutorepondeur()

  return (
    <Section aside {...props}>
      <Card variant='paper' sx={{textAlign: `center`, background:`#F3F3F3`}}>
        <Heading as="h4" sx={{fontSize:`1.4em`}}>
          {props.newsletterInfo.headerTitle}
        </Heading>
        {!props.omitDescription && <Text sx={{m:3}}>{props.newsletterInfo.description}</Text>}
        <Box sx={{width:`100%`, display:[`none`, `block`]}}>
          <img src={props.newsletterInfo.picture.fixed.src} style={{maxWidth:`100%`}} alt="inscription-newsletter" />
        </Box>
        <Box sx={{maxWidth: `600px`, marginLeft:`auto`, marginRight:`auto`}}>
          <NewsletterForm
            {...{ handleSubmit, canSubmit, submitting, message, success }}
            buttonTitle={props.newsletterInfo.buttonTitle}
          />
        </Box>
      </Card>
    </Section>
  )
}

export default NewsletterCompact
