import React, {useState} from 'react'
import { Flex, Box, Card, Text, Link } from 'theme-ui'
import NewsletterForm from '@components/NewsletterForm'
import Section from '@components/Section'
import useSGAutorepondeur from '@helpers/useSGAutorepondeur'
import { FaTimesCircle } from 'react-icons/fa'

const NewsletterHorizontal = props => {
  const {
    handleSubmit,
    canSubmit,
    submitting,
    message,
    success,
  } = useSGAutorepondeur()

  const [show, setShow] = useState(true)

  const styles = {
    link:{
      textDecoration: `none`,
      color:`black`,
      textAlign: `right`,
    },
    column1:{
      display: [`none`, `none`, `block`],
      textAlign: `center`,
      paddingTop: `1rem`,
      paddingX: `2rem`
    }
  }

  const closeModal = () => {
    setShow(false)
  }

  return show && (
      <Section aside {...props}>
        <Card bg="black">
          <Flex>
            <Box sx={styles.column1}>
              <Text style={{color:`white`, paddingLeft:`1rem`, paddingTop:`0.5rem`}}>
                <b style={{marginRight: `5px`}}>{props.newsletterInfo.headerTitle}</b>
              </Text>
            </Box>
            <Box sx={styles.column}>
              <NewsletterForm
                {...{ handleSubmit, canSubmit, submitting, message, success }}
                buttonTitle={props.newsletterInfo.buttonTitle}
                inline={true}
                buttonSm={true}
              />
            </Box>            
            <Box style={{marginTop:`1rem`, marginLeft:`20px`}}>
              <Link style={{cursor:`pointer`}} onClick={closeModal} sx={styles.link}><FaTimesCircle style={{fontSize:`1.5em`, color:`white`}}/></Link>
            </Box>
          </Flex>
        </Card>
      </Section>
    )
}

export default NewsletterHorizontal
