import React, {useEffect,useState} from 'react'
import { Card as CardComponent, Box, Heading } from 'theme-ui'
import { Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Card from '@components/Card'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import NewsletterCompact from '@widgets/NewsletterCompact'
import NewsletterHorizontal from '@widgets/NewsletterHorizontal'
import ExitIntent from '@widgets/ExitIntent'
import EntranceModal from '@components/EntranceModal'
import ExitIntentModal from '@components/ExitIntentModal'
import { PostImage, PostBody, PostComments, PostTagsShare } from '@widgets/Post'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { Helmet } from "react-helmet"

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, postPageSettings, previous, next },
  location,
  ...props
}) => {

  const styles = {
    topBar:{
      zIndex:`5`,
      width:`100%`,
      position: `-webkit-sticky`, /* Safari */
      position: `sticky`,
      top:0,
      display:[`none`, `block`]
    },
    topDescription : {
      border: `2px dashed #BE7155`,
      fontSize: `1rem`,
      padding: `0.5rem 1rem`
    }
  }

  const newsletterSide = postPageSettings.edges[0].node.newsletterSide
  const newsletterTopBar = postPageSettings.edges[0].node.newsletterTopBar
  const newsletterBottom = postPageSettings.edges[0].node.newsletterBottom
  const exitIntentModalInfo = postPageSettings.edges[0].node.exitIntentModal
  const entranceModalInfo = postPageSettings.edges[0].node.entranceModal
  const topDescription = postPageSettings.edges[0].node.topDescription && postPageSettings.edges[0].node.topDescription

  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : []),
  ]
  const { pageContext: { services = {}, siteUrl } = {}, path } = props

  const [showPopupExit, setShowPopupExit] = useState(false)
  const [showPopupEntrance, setShowPopupEntrance] = useState(false)

  //Dispaly modal exit 
  useEffect(() => {
    if (document.cookie.indexOf("modal_exit_seen=true") < 0) {
      const removeExitIntent = ExitIntent({
        threshold: 30,
        eventThrottle: 100,
        onExitIntent: () => {
          setShowPopupExit(true)
        },
      })
      return () => {
        removeExitIntent()
      }
    }
  })

  //Display modal entrance
  useEffect(() => {
    if (document.cookie.indexOf("modal_entrance_seen=true") < 0) 
    {
      const interval = setInterval(() => {
        setShowPopupEntrance(true)
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [])

  const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <p style={{margin:0}}>{children}</p>,
        [INLINES.HYPERLINK]: (node, children) => <a href={node.data.uri} target="_blank" rel="noopener noreferrer">{children}</a>,
    }
  };

  return (
    <>
      <Helmet>
        <script async src="//cdn.embedly.com/widgets/platform.js" charset="UTF-8"></script>
      </Helmet>
      {exitIntentModalInfo && <ExitIntentModal data={exitIntentModalInfo} show={showPopupExit} />}
      {entranceModalInfo && <EntranceModal data={entranceModalInfo} show={showPopupEntrance} />}
      <Box bg="black" sx={styles.topBar}>
        <Stack effectProps={{ effect: 'none' }}>
          <Main>
           <NewsletterHorizontal newsletterInfo={newsletterTopBar} omitTitle/>
          </Main>
        </Stack>
      </Box>
      <Seo {...post} siteUrl={siteUrl+path} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <Main>
          <Heading as="h1" sx={{ fontSize: `3rem` }}>
            {post.title}
          </Heading>
          <p style={styles.topDescription} >{renderRichText(topDescription, options)}</p>
          {post.displayHero ?? <PostImage {...post} />}
        </Main>
        <Sidebar pl={[null, 2, 3, 3]}>
          <NewsletterCompact newsletterInfo={newsletterSide} omitTitle omitDescription />
        </Sidebar>
      </Stack>
      <Divider space={1} />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper-lg'>
            <PostBody {...post} />
            <PostTagsShare {...post} location={location} />
            {services.disqus && <PostComments {...post} />}
          </CardComponent>
          <Divider />
          <AuthorExpanded author={post.author} />
          <Divider />
          <NewsletterCompact newsletterInfo={newsletterBottom} simple/> 
          <Divider />
          {post.category && (
            <CardList
              title='Dans la même catégorie'
              nodes={relatedPosts}
              variant={['horizontal-md']}
              columns={[1, 2, 2, 2]}
              limit={6}
              distinct
              omitAuthor
              omitFooter
            />
          )}
        </Main>
      </Stack>
    </>
  )
}

export default Post
